import flatpickr from 'flatpickr'
import moment from 'moment-timezone'

document.addEventListener('turbolinks:load', () => {
  $.rails.confirm = function(message, element) {
    let $element = $(element)

    if ($element.data('ok') && $element.data('cancel')) {
      showConfirmModal({
        title: '',
        message: message,
        buttonYes: $element.data('ok'),
        buttonNo: $element.data('cancel'),
  
        onYes: function (event) {
          event.preventDefault()
          let old = $.rails.confirm
          $.rails.confirm = function() { return true }
          $element.get(0).click()
          $.rails.confirm = old
        },
  
        onNo: function () {
        }
      });
      return false;
    }
    showConfirmModal({
      title: '',
      message: message,
      buttonYes: 'OK',
      buttonNo: 'Cancel',

      onYes: function (event) {
        event.preventDefault()
        let old = $.rails.confirm
        $.rails.confirm = function() { return true }
        $element.get(0).click()
        $.rails.confirm = old
      },

      onNo: function () {
      }
    });
    return false;
  }

  // Constants for datetime format
  var dateTimeDisplayFormat = 'dddd, MMMM D, YYYY, h:mm a';
  var dateTimeUTCFormat = 'Z';

  // Datetime pickers
  [...document.querySelectorAll('[data-type="datetime"]')].forEach((time) => {
    const isDue = !!time.getAttribute('data-due');
    const timeZone = time.getAttribute('data-timezone');

    let pickerOptions = {
      enableTime: true,
      altInput: true,
      altFormat: dateTimeDisplayFormat,
      dateFormat: dateTimeUTCFormat,

      parseDate(dateString, format) {
        if (format == dateTimeUTCFormat) {
          const datetime = moment.tz(moment(dateString, 'YYYY-MM-DD HH:mm:ss Z'), timeZone);
          return new Date(
            datetime.year(),
            datetime.month(),
            datetime.date(),
            datetime.hour(),
            datetime.minute(),
            datetime.second()
          );
        }

        return new Date(dateString);
      },

      formatDate(date, format) {
        const in_timezone = moment.tz([
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        ], timeZone);

        if (format == dateTimeUTCFormat) {
          return moment(in_timezone).utc().format();
        } else if (format == dateTimeDisplayFormat) {
          return in_timezone.format(format);
        }

        return null;
      },

      onChange(date, _dateStr, instance) {
        if (!isDue || !date || date.length <= 0) {
          return;
        }

        const minTime = moment.tz(moment().add(1, 'days'), timeZone);
        const minDateTime = new Date(
          minTime.year(),
          minTime.month(),
          minTime.date(),
          minTime.hour(),
          minTime.minute(),
          minTime.second()
        );

        if (date[0] < minDateTime) {
          instance.setDate(minDateTime);
        }
      }
    };

    if (isDue) {
      const defaultTime = time.getAttribute('value');
      if (!defaultTime) {
        const minTime = moment.tz(moment().add(1, 'days'), timeZone);
        const minDateTime = new Date(
          minTime.year(),
          minTime.month(),
          minTime.date(),
          minTime.hour(),
          minTime.minute(),
          minTime.second()
        );

        pickerOptions['defaultDate'] = minDateTime;
      }
    }

    flatpickr(time, pickerOptions);
  });

  // Sortable columns
  if ($('form[role="filter"]').length > 0) {
    $('th[role="columnheader"] > a').on('click', function (event) {
      let filter = $('form[role="filter"] #filter').val();
      let target = $(event.target);
      let href = target.attr('href') + '&filter=' + filter;
      target.attr('href', href);
    });
  };

  // Link in instructions column
  let instruction_cells = $('td.cell-data--tooltip-field');

  if (instruction_cells.length > 0) {
    instruction_cells.on('click', function (event) {
      let blank_link = $(event.target).children('a[target="_blank"]');
      let target = blank_link.attr('href');

      event.preventDefault();
      window.open(target);
      return (false)
    });
  };

  // Link in Article column
  let article_cells = $('td.cell-data .article-link').closest('td.cell-data');
  if (article_cells.length > 0) {
    article_cells.on('click', function (event) {
      let article_link = $(event.currentTarget).children('a.article-link');
      let target = article_link.attr('href');

      event.preventDefault();
      window.open(target, '_self');
      return false
    });
  }

  // Radio button labels act like buttons
  $('.form-group .radio-wrapper label').on('click', function (event) {
    let group = $(event.target).parents('.radio-wrapper');
    let input = group.find('input[type="radio"]');

    input.click();
  });

  // Tooltips
  $('[data-toggle="tooltip"]').tooltip()

  // Enum other inputs
  $('[data-other-element]').each(function (index, el) {
    let input_el = $(`#${$(el).attr('data-other-element')}`);
    let other_val = $(el).attr('data-other-value');

    $(el).on('change', function (ev) {
      let selValue = ev.target.options[ev.target.selectedIndex].value;
      if (selValue == other_val) {
        input_el.removeClass('d-none');
      } else {
        input_el.addClass('d-none');
        input_el.val('');
      }
    })
  })

  $('a.button.disabled').click(function (e) {
    e.preventDefault();
    return false;
  });

  // Word count
  if ($('input[type="number"]').length > 0) {
    const word_count_input = $('input[type="number"]');
    const ALLOWED_CHARACTERS_REGEX = /[0-9]+/;

    word_count_input.on('keypress', function (ev) {
      if (!ALLOWED_CHARACTERS_REGEX.test(event.key)) {
        ev.preventDefault();
      }
    });

    word_count_input.on('change', function (ev) {
      let target = $(ev.target);
      let value = parseInt(target.val());

      if (value < 0) {
        target.val('');
        ev.preventDefault();
      }
    });
  }

  // Order form additional emails
  if ($('#order_additional_emails_yes').length > 0) {
    const yes_el = $('#order_additional_emails_yes');
    const no_el = $('#order_additional_emails_no');
    const input_el = $('#order_additional_emails_input');
    const form_input_el = $('#order_additional_emails');
    const default_val = form_input_el.val().trim();

    if (default_val) {
      yes_el.prop('checked', true);
      input_el.val(default_val);
      input_el.show();
    } else {
      no_el.prop('checked', true);
      input_el.val('');
      input_el.hide();
    }

    yes_el.change(function () {
      if (this.checked) {
        input_el.show();
      } else {
        input_el.val('');
        input_el.hide();
      }
    });

    no_el.change(function () {
      if (this.checked) {
        input_el.val('');
        input_el.hide();
      } else {
        input_el.show();
      }
    });
  }

  // Order form special instructions
  if ($('#order_special_instructions_input').length > 0) {
    const input_el = $('#order_special_instructions_input');
    const form_input_el = $('#order_special_instructions');
    const default_val = form_input_el.val().trim();

    if (default_val) {
      input_el.val(default_val);
    } else {
      input_el.val('');
    }
  }

  // Like clean copy group
  if ($('#order_can_clean_copy').length > 0) {
    if ($('#order_can_clean_copy').val() === 'yes') {
      $('#order_clean_copy_group').show();
    } else {
      $('#order_clean_copy_group').hide();
      $('#order_like_clean_copy_false').prop('checked', true);
    }
  }

  if ($('#upload.btn').length > 0) {
    $('#upload.btn').on('click', function (e) {
      $(e.target).removeClass('error');
    })
  }

  // Order form validation
  if ($('#order_form').length > 0) {
    $('#order_form').on('submit', function (e) {
      // Validation
      if (!validateOrderForm()) {
        e.preventDefault();

        setTimeout(function () {
          $('#order_form input[type="submit"]').removeAttr('disabled');
        }, 100);

        return;
      }

      // Copy additional emails value
      const additional_emails_yes_el = $('#order_additional_emails_yes');
      const additional_emails_input_el = $('#order_additional_emails_input');
      const additional_emails_form_input_el = $('#order_additional_emails');

      if (additional_emails_yes_el.is(':checked')) {
        additional_emails_form_input_el.val(additional_emails_input_el.val());
      } else {
        additional_emails_form_input_el.val('');
      }

      // Copy special instructions value
      const special_instructions_input_el = $('#order_special_instructions_input');
      const special_instructions_form_input_el = $('#order_special_instructions');

      special_instructions_form_input_el.val(special_instructions_input_el.val());
    });
  }

  // Admin Send Order & Close button
  if ($('#admin_send_order').length > 0) {
    const sendButton = $('#admin_send_order');
    var errorMessage;

    sendButton.click(function (e) {
      sendButton.prop('disabled', true);

      const orderID     = sendButton.data('order');
      const fulfillUrl  = `/admin/orders/${orderID}/fulfill`;
      const redirectUrl = `/admin/orders/${orderID}?fulfilled`;

      $.post(
        fulfillUrl,
        function (data) {
          if (data.result) {
            sendButton.prop('disabled', false);
            window.location = redirectUrl;
          } else {
            if (data.matched) {
              errorMessage = data.error || 'Something went wrong while completing the order.'
              showAlertModal('Error', errorMessage);
              sendButton.prop('disabled', false);
            } else {
              showConfirmModal({
                title: 'There is an issue with this order',
                message: `${data.error} How would you like to proceed?`,
                buttonYes: 'Submit the files to the client',
                buttonNo: 'Cancel and re-upload',

                onYes: function () {
                  $.post(
                    fulfillUrl,
                    { force: true },
                    function (data) {
                      if (data.result) {
                        window.location = redirectUrl;
                      } else {
                        errorMessage = data.error || 'Something went wrong while completing the order.'
                        showAlertModal('Error', errorMessage);
                      }

                      sendButton.prop('disabled', false);
                    }
                  ).fail(function () {
                    showAlertModal('Error', 'Something went wrong while completing the order.');
                    sendButton.prop('disabled', false);
                  });
                },

                onNo: function () {
                  sendButton.prop('disabled', false);
                }
              });
            }
          }
        }
      ).fail(function (response) {
        errorMessage = response.error || 'Something went wrong while completing the order.'
        showAlertModal('Error', errorMessage);
        sendButton.prop('disabled', false);
      });
    })
  }
});

function validateOrderForm() {
  if ($('#order_due_at').length > 0) {
    const val = $('#order_due_at').val();
    if (!val) {
      showAlertModal('Error', 'The due time is required.');
      return false;
    }
  }

  const uppyArr = $('[data-uppy]');

  for (let i = 0; i < uppyArr.length; i++) {
    const uppy = $(uppyArr[i]);
    const fieldName = uppy.attr('data-uppy');

    if (fieldName == 'order[source_files][]') {
      const fields = uppy.find('[data-pending-upload]');
      const existingFiles = uppy.find('ul[data-behavior="uppy-uploaded-file-list"] li');

      if (fields.length <= 0 && existingFiles.length <= 0) {
        showAlertModal('Error', 'You need to upload at least one Word file or PDF.');
        $('#upload.btn').addClass('error');
        return false;
      }
    }
  }

  return true;
}

// Alert modal
window.showAlertModal = function (_title = '', message = '', button = 'Close') {
  let modal = $('#alert-modal');

  if (modal.length <= 0) {
    return;
  }

  modal.find('.modal-title').html('');
  modal.find('.modal-body p').html(message);
  modal.find('.modal-footer button').html(button);
  modal.modal('show');
};

// Confirm modal
window.showConfirmModal = function (options = {}) {
  let modal = $('#confirm-modal');

  if (modal.length <= 0) {
    return;
  }

  options = options || {};
  modal.find('.modal-title').html(options.title || '');
  modal.find('.modal-body p').html(options.message || '');
  modal.find('.modal-footer button[data-role="yes"]').html(options.buttonYes || 'Yes');
  modal.find('.modal-footer button[data-role="no"]').html(options.buttonNo || 'No');
  modal.find('.modal-footer button[data-role="yes"]').unbind('click');
  modal.find('.modal-footer button[data-role="no"]').unbind('click');

  if (options.onYes) {
    modal.find('.modal-footer button[data-role="yes"]').bind('click', options.onYes);
  }

  if (options.onNo) {
    modal.find('.modal-footer button[data-role="no"]').bind('click', options.onNo);
  }

  modal.modal('show');
};

