// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "popper.js"
import "bootstrap"
import "@fortawesome/fontawesome-free"
import "../stylesheets/fontawesome-fonts"
import 'trix/dist/trix.css';

import FroalaEditor from 'froala-editor/js/froala_editor.min.js';
global.FroalaEditor = FroalaEditor;

window.jQuery = $
window.$ = $

require("tagsinput/jquery.tagsinput")

require("../common/uppy_activestorage")
require("../common/common")

require("trix")
require("@rails/actiontext")
require("froala-editor/js/plugins/word_paste.min")
